import { __decorate, __metadata, __param } from "tslib";
import { Component, Inject } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
let EditRecordedWaste = class EditRecordedWaste {
    constructor(DialogRef, waste) {
        this.DialogRef = DialogRef;
        this.waste = waste;
    }
    ngOnInit() {
        const builder = new FormBuilder();
        this.FormGroup = builder.group({
            ewcCode: [null, Validators.required],
            qty: [null, Validators.required],
            wasteOperationCode: [null, Validators.required],
        });
        Object.keys(this.waste).forEach((name) => {
            if (this.FormGroup.controls[name]) {
                this.FormGroup.controls[name].patchValue(this.waste[name]);
            }
        });
    }
    onSubmit() {
        this.DialogRef.close(this.FormGroup.getRawValue());
    }
};
EditRecordedWaste = __decorate([
    Component({
        selector: "edit-recorded-waste",
        template: require('./component.html'),
        styles: [require('./component.scss')],
    }),
    __param(1, Inject(MAT_DIALOG_DATA)),
    __metadata("design:paramtypes", [MatDialogRef, Object])
], EditRecordedWaste);
export { EditRecordedWaste };
