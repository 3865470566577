import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Rounds } from './rounds';
import { AwSnackBar } from './aw-snack-bar';
let DriverRound = class DriverRound {
    constructor(Rounds, snackBar) {
        this.Rounds = Rounds;
        this.snackBar = snackBar;
        this.loading = new BehaviorSubject(false);
        this.Round = new BehaviorSubject({
            Consignments: null,
            Collections: null,
            DropOffs: null,
        });
    }
    load(useApi) {
        return new Promise((resolve, reject) => {
            try {
                this.loading.next(true);
                this.Rounds
                    .loadRound(useApi)
                    .then((Round) => this.setRound(Round), error => {
                    this.handleError(error);
                    reject(error);
                })
                    .then((Round) => {
                    this.loading.next(false);
                    resolve(Round);
                }, error => {
                    this.handleError(error);
                    reject(error);
                });
            }
            catch (error) {
                this.handleError(error);
                reject(error);
            }
        });
    }
    handleError(error) {
        this.loading.next(false);
        return this.snackBar.handleError(new Error('Could not load today\'s round.'));
    }
    setAllStatuses(Round) {
        return new Promise((resolve, reject) => {
            try {
                Round.Collections.forEach((Collection) => {
                    Collection.collected = this.collectionCollected(Collection);
                    Collection.declined = this.collectionDeclined(Collection);
                });
                Round.DropOffs.forEach((DropOff) => {
                    let Consignment = Round.Consignments[DropOff.consignmentId] || null;
                    let Collection = !!Consignment && Round.Collections.filter((Collection) => {
                        return Collection.consignmentId == Consignment.consignmentId;
                    }).pop() || null;
                    DropOff.collected = this.consignmentCollected(Consignment) || this.collectionCollected(Collection);
                    DropOff.droppedOff = this.dropOffDroppedOff(DropOff);
                    DropOff.declined = this.dropOffDeclined(Collection, DropOff);
                });
                resolve(Round);
            }
            catch (error) {
                reject(error);
            }
        });
    }
    consignmentCollected(Consignment) {
        return !!Consignment && !!Consignment.collectedTimestamp;
    }
    collectionCollected(Collection) {
        return !this.collectionDeclined(Collection) && !!Collection && (!!Collection.collectedTimestamp || !!Collection.Signatures);
    }
    collectionDeclined(Collection) {
        return !!Collection && (!!Collection.declinedTimestamp || !!Collection.Signatures && !!Collection.Signatures.decline);
    }
    dropOffDroppedOff(DropOff) {
        return !this.dropOffDeclined(null, DropOff) && !!DropOff && (!!DropOff.droppedOffTimestamp || !!DropOff.Signatures);
    }
    dropOffDeclined(Collection, DropOff) {
        return this.collectionDeclined(Collection) || (!!DropOff && (!!DropOff.declinedTimestamp || !!DropOff.Signatures && !!DropOff.Signatures.decline));
    }
    saveRoundToLocalStorage() {
        return this.Rounds.saveRoundToLocalStorage(this.Round.getValue());
    }
    setRound(Round) {
        return new Promise((resolve, reject) => {
            try {
                this
                    .setAllStatuses(Round)
                    .then((Round) => {
                    this.Round.next(Round);
                    resolve(Round);
                }, error => reject(error));
            }
            catch (error) {
                reject(new Error(error));
            }
        });
    }
};
DriverRound = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [Rounds,
        AwSnackBar])
], DriverRound);
export { DriverRound };
