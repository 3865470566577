var Signature_1;
import { __decorate, __metadata } from "tslib";
import { Input, Component, ViewChild, forwardRef } from "@angular/core";
import { FormGroup, NG_VALUE_ACCESSOR, } from "@angular/forms";
import { SignaturePadComponent } from "@almothafar/angular-signature-pad";
import { SignatureFormFactory } from "../../factories/signature";
let Signature = Signature_1 = class Signature {
    constructor() {
        this.options = {};
    }
    /**
     * Can use this to set the height and width programatically to the window size - toolbar
     */
    ngAfterViewInit() {
        this.signaturePad.clear();
        let signatureBox = document.getElementsByClassName("signature")[0];
        let canvas = document.getElementsByTagName("canvas")[0];
        canvas.width = signatureBox.clientWidth;
        canvas.height = signatureBox.clientHeight;
        !!this.FormGroup.get("signature").value &&
            this.blobToDataURL(this.FormGroup.get("signature").value).then((dataUri) => this.signaturePad.fromDataURL(dataUri));
    }
    writeValue(value) { }
    registerOnChange(fn) { }
    registerOnTouched() { }
    drawComplete() {
        this.FormGroup.get("signature").setValue(this.dataUriToBlob(this.signaturePad.toDataURL()));
        this.FormGroup.get("date").setValue(SignatureFormFactory.formatDate(new Date()));
    }
    resetSignature() {
        this.FormGroup.get("signature").setValue(null);
        this.signaturePad.clear();
    }
    getDataUriMimeType(dataUri) {
        const regex = new RegExp(/^data:(.+);/);
        return !!dataUri ? regex.exec(dataUri)[1] : null;
    }
    getDataUriData(dataUri) {
        const regex = new RegExp(/^data:(?:.+);base64,(.*)$/);
        return regex.exec(dataUri)[1];
    }
    dataUriToBlob(dataUri) {
        const byteString = atob(this.getDataUriData(dataUri));
        const mimeString = this.getDataUriMimeType(dataUri);
        const ab = new ArrayBuffer(byteString.length);
        let ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
    }
    blobToDataURL(blob) {
        return new Promise((resolve, reject) => {
            try {
                let reader = new FileReader();
                reader.onload = (event) => resolve(event.target.result);
                reader.readAsDataURL(blob);
            }
            catch (error) {
                reject(error);
            }
        });
    }
};
__decorate([
    ViewChild(SignaturePadComponent),
    __metadata("design:type", SignaturePadComponent)
], Signature.prototype, "signaturePad", void 0);
__decorate([
    Input(),
    __metadata("design:type", FormGroup)
], Signature.prototype, "FormGroup", void 0);
Signature = Signature_1 = __decorate([
    Component({
        selector: "signature-box",
        template: require('./component.html'),
        styles: [require('./component.scss')],
        providers: [
            {
                provide: NG_VALUE_ACCESSOR,
                useExisting: forwardRef(() => Signature_1),
                multi: true,
            },
        ],
    })
], Signature);
export { Signature };
