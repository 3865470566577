import { __decorate, __metadata } from "tslib";
import { Component, Input } from '@angular/core';
let ConsignmentDetails = class ConsignmentDetails {
};
__decorate([
    Input(),
    __metadata("design:type", Object)
], ConsignmentDetails.prototype, "Consignment", void 0);
ConsignmentDetails = __decorate([
    Component({
        selector: 'consignment-details',
        template: require('./component.html'),
        styles: [require('./component.scss')]
    })
], ConsignmentDetails);
export { ConsignmentDetails };
