import { __decorate, __metadata } from "tslib";
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
let AddImage = class AddImage {
    constructor() {
        this.maxFileSizeMB = 10; // This is a standard attachment size in email
        this.invalidMimeType = null;
        this.invalidFileSize = null;
    }
    ngOnInit() {
        !!this.FormControl.value && this.setImagePreview(this.FormControl.value);
    }
    onImageChange(event) {
        this.invalidMimeType = null;
        this.invalidFileSize = null;
        let fileList = event.target.files;
        if (fileList.length > 0) {
            let reader = new FileReader();
            reader.onload = ((file) => (event) => {
                this.mimeTypeValid(file) && this.fileSizeValid(file) && this.addImage(file);
            })(event.target.files[0]);
            reader.readAsArrayBuffer(event.target.files[0]);
        }
    }
    onPreviewLoad() {
        URL.revokeObjectURL(this.imageFileOutput.nativeElement.src);
    }
    addImage(blob) {
        this.FormControl.patchValue(blob);
        this.setImagePreview(blob);
    }
    setImagePreview(image) {
        this.imageFileOutput.nativeElement.src = URL.createObjectURL(image);
    }
    mimeTypeValid(blob) {
        const mimeType = blob.type;
        const valid = !!mimeType.match(/^image\/.*$/);
        if (!valid) {
            this.imageFileInput.nativeElement.value = null;
            this.invalidMimeType = mimeType;
        }
        return valid;
    }
    fileSizeValid(blob) {
        const fileSizeInMB = Number((blob.size / (1024 * 1024)).toFixed(2));
        const valid = (fileSizeInMB < this.maxFileSizeMB);
        if (!valid) {
            this.imageFileInput.nativeElement.value = null;
            this.invalidFileSize = fileSizeInMB;
        }
        return valid;
    }
    removeImage() {
        this.imageFileOutput.nativeElement.src = null;
        this.FormControl.reset();
    }
};
__decorate([
    ViewChild('imageFileInput'),
    __metadata("design:type", Object)
], AddImage.prototype, "imageFileInput", void 0);
__decorate([
    ViewChild('imageFileOutput'),
    __metadata("design:type", ElementRef)
], AddImage.prototype, "imageFileOutput", void 0);
__decorate([
    Input(),
    __metadata("design:type", FormControl)
], AddImage.prototype, "FormControl", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], AddImage.prototype, "label", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], AddImage.prototype, "required", void 0);
AddImage = __decorate([
    Component({
        selector: 'add-image',
        template: require('./component.html'),
        styles: [require('./component.scss')],
    })
], AddImage);
export { AddImage };
