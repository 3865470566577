import { __decorate, __metadata } from "tslib";
import { Injectable } from "@angular/core";
import { Router, } from "@angular/router";
import { Observable } from "rxjs";
import { Auth } from "./auth";
let DriverGuard = class DriverGuard {
    constructor(Router, Auth) {
        this.Router = Router;
        this.Auth = Auth;
    }
    canActivate(route, state) {
        return new Observable((observer) => {
            const url = state.url;
            this.Auth.isLoggedIn() && this.loggedIn(url, observer);
            this.Auth.loginFromLocalStorage().then(() => this.loggedIn(url, observer), () => this.notLoggedIn(url, observer));
        });
    }
    loggedIn(url, observer) {
        let routeActivate = url == "/dashboard";
        !routeActivate && this.Router.navigate(["/dashboard"]);
        observer.next(routeActivate);
    }
    notLoggedIn(url, observer) {
        let routeActivate = url == "/login";
        !routeActivate && this.Router.navigate(["/login"]);
        observer.next(routeActivate);
    }
};
DriverGuard = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [Router, Auth])
], DriverGuard);
export { DriverGuard };
