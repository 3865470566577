import { __decorate, __metadata } from "tslib";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map, mergeWith } from "rxjs/operators";
import { of, fromEvent } from "rxjs";
import { Auth } from "./auth";
import { environment } from "../constant/environment";
const UNORTHORIZED = 401;
const FORBIDDEN = 403;
const ERROR = 500;
let Api = class Api {
    constructor(Http, Auth) {
        this.Http = Http;
        this.Auth = Auth;
        this.isConnected = of(navigator.onLine).pipe(mergeWith(fromEvent(window, "online").pipe(map(() => true)), fromEvent(window, "offline").pipe(map(() => false))));
        this.isConnected.subscribe((isConnected) => (this._isConnected = isConnected));
    }
    request(endpoint, options, jwt, method) {
        return new Promise((resolve, reject) => {
            try {
                !jwt && (jwt = this.Auth.getJwt());
                if (!!jwt) {
                    options.headers = { Authorization: jwt };
                }
                if (this._isConnected) {
                    this.doRequest(endpoint, options, method, resolve, reject);
                }
                else {
                    reject("Network not connected.");
                }
            }
            catch (error) {
                reject(error);
            }
        });
    }
    s3PutObject(endpoint, putObject) {
        return new Promise((resolve, reject) => {
            try {
                if (this._isConnected) {
                    this.Http.put(endpoint, putObject).subscribe({
                        next: (Response) => {
                            resolve(Response);
                        },
                        error: (Response) => {
                            let message = "An error occurred";
                            if (UNORTHORIZED == Response.status) {
                                message = "Unauthorised: Invalid user credentials.";
                            }
                            if (FORBIDDEN == Response.status) {
                                message = "Forbidden: You do not have sufficient privileges.";
                            }
                            if (ERROR == Response.status) {
                                message = new Error(Response.statusText).toString();
                            }
                            reject(message);
                        },
                    });
                }
                else {
                    reject("Network not connected.");
                }
            }
            catch (error) {
                reject(error);
            }
        });
    }
    login(login) {
        let body = {
            vehicleRegistration: login.vehicleRegistration,
            pinCode: login.pinCode,
        };
        let endpoint = environment.drivers.endpoint() + "login";
        const method = "POST";
        let options = { body: body };
        return this.request(endpoint, options, null, method);
    }
    logout() {
        this.Auth.isLoggedIn() && this.Auth.logout();
    }
    doRequest(endpoint, options, method, resolve, reject) {
        this.Http.request(method || "GET", endpoint, options).subscribe({
            next: (Response) => {
                resolve(Response);
            },
            error: (Response) => {
                let message = "An error occurred";
                if (UNORTHORIZED == Response.status) {
                    message = "Unauthorised: Invalid user credentials.";
                }
                if (FORBIDDEN == Response.status) {
                    message = "Forbidden: You do not have sufficient privileges.";
                }
                if (ERROR == Response.status) {
                    message = new Error(Response.statusText).toString();
                }
                if (!!this.Auth.getJwt()) {
                    this.logout();
                    message = "Your session is no longer valid.";
                }
                reject(message);
            },
        });
    }
};
Api = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [HttpClient, Auth])
], Api);
export { Api };
