import { __decorate } from "tslib";
import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
let SignatureFormFactory = class SignatureFormFactory {
    static form(Signature) {
        let _FormBuilder = new FormBuilder();
        let FormGroup = _FormBuilder.group({
            'signature': [null, [Validators.required]],
            'name': [null, [Validators.required]],
            'date': [null, [Validators.required]],
            'wasteImage': [null],
        });
        !!Signature &&
            Object
                .keys(Signature)
                .map(name => {
                let control = FormGroup.get(name);
                !!control && control.patchValue(Signature[name]);
            });
        return FormGroup;
    }
    static validateSignature(control) {
        return (control.value instanceof Object) ? null : {
            validSignature: {
                valid: false,
            },
        };
    }
    static formatDate(date) {
        date = date || new Date();
        return date.toISOString().slice(0, 16);
    }
};
SignatureFormFactory = __decorate([
    Injectable()
], SignatureFormFactory);
export { SignatureFormFactory };
