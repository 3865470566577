import { __decorate, __metadata } from "tslib";
import { Component, Input } from "@angular/core";
import { FormArray } from "@angular/forms";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { EditRecordedWaste } from "../edit-recorded-waste/component";
let RecordedWasteTable = class RecordedWasteTable {
    constructor(Dialog) {
        this.Dialog = Dialog;
    }
    edit(FormGroup) {
        let config = new MatDialogConfig();
        config.data = FormGroup.getRawValue();
        config.disableClose = true;
        let Dialog = this.Dialog.open(EditRecordedWaste, config);
        Dialog.afterClosed().subscribe((waste) => !!waste && FormGroup.setValue(waste));
    }
};
__decorate([
    Input(),
    __metadata("design:type", FormArray)
], RecordedWasteTable.prototype, "FormArray", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], RecordedWasteTable.prototype, "editable", void 0);
RecordedWasteTable = __decorate([
    Component({
        selector: "recorded-waste-table",
        template: require('./component.html'),
        styles: [require('./component.scss')],
    }),
    __metadata("design:paramtypes", [MatDialog])
], RecordedWasteTable);
export { RecordedWasteTable };
