import { __decorate } from "tslib";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { AddImage } from "./add-image/component";
import { AnyWasteMaterialModule } from "../material.module";
let DirectivesModule = class DirectivesModule {
};
DirectivesModule = __decorate([
    NgModule({
        imports: [CommonModule, AnyWasteMaterialModule, HttpClientModule],
        declarations: [AddImage],
        entryComponents: [],
        providers: [],
        schemas: [CUSTOM_ELEMENTS_SCHEMA],
        exports: [AddImage],
    })
], DirectivesModule);
export { DirectivesModule };
