export const hazardCodes = [
    "17 06 05",
    "17 05 03",
    "16 02 13",
    "16 02 14",
    "17 06 01",
    "16 02 15",
    "20 01 35",
    "20 01 36",
    "20 01 21",
    "20 01 23",
    "16 02 11",
    "16 05 04",
    "16 06 01",
    "20 01 33",
    "16 06 02",
    "13 05 07",
    "16 02 16",
    "19 12 11",
    "13 02 05",
];
// Array structure to future proof additional codes
export const dangerousGoodsCodes = ["16 06 01"];
