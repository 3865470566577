import { __decorate, __metadata } from "tslib";
import { Injectable } from "@angular/core";
import { environment } from "../constant/environment";
import { Api } from "./api";
let CollectionApi = class CollectionApi {
    constructor(Api) {
        this.Api = Api;
    }
    list() {
        const method = "GET";
        let endpoint = environment.drivers.endpoint() + "collections";
        return this.Api.request(endpoint, {}, null, null);
    }
};
CollectionApi = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [Api])
], CollectionApi);
export { CollectionApi };
