import { __decorate, __metadata, __param } from "tslib";
import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
let ConsigneeCertificateDialog = class ConsigneeCertificateDialog {
    constructor(DialogRef, data) {
        this.DialogRef = DialogRef;
        this.data = data;
    }
    ngOnInit() {
        this.FormGroup = this.data.FormGroup;
        this.DropOff = this.data.DropOff || null;
    }
    onSubmit() {
        this.DialogRef.close();
    }
};
ConsigneeCertificateDialog = __decorate([
    Component({
        selector: "consignee-certificate",
        template: require('./consignee.html'),
        styles: [require('./certificate.scss')],
    }),
    __param(1, Inject(MAT_DIALOG_DATA)),
    __metadata("design:paramtypes", [MatDialogRef, Object])
], ConsigneeCertificateDialog);
export { ConsigneeCertificateDialog };
