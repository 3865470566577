import { __decorate, __metadata, __param } from "tslib";
import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import containers from "../../../constant/container-types";
let DangerousGoodsDialog = class DangerousGoodsDialog {
    constructor(DialogRef, data) {
        this.DialogRef = DialogRef;
        this.data = data;
        this.vehicles = containers;
    }
    ngOnInit() {
        this.FormGroup = this.data;
    }
    onSubmit() {
        this.DialogRef.close();
    }
};
DangerousGoodsDialog = __decorate([
    Component({
        selector: "dangerous-goods",
        template: require('./component.html'),
        styles: [require('./component.scss')],
    }),
    __param(1, Inject(MAT_DIALOG_DATA)),
    __metadata("design:paramtypes", [MatDialogRef, Object])
], DangerousGoodsDialog);
export { DangerousGoodsDialog };
