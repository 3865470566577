import { __decorate, __metadata } from "tslib";
import { Injectable } from "@angular/core";
import { environment } from "../constant/environment";
import { Api } from "./api";
let ConsignmentApi = class ConsignmentApi {
    constructor(Api) {
        this.Api = Api;
    }
    driverList() {
        let endpoint = environment.drivers.endpoint() + "consignments";
        return this.Api.request(endpoint, {}, null, null);
    }
};
ConsignmentApi = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [Api])
], ConsignmentApi);
export { ConsignmentApi };
