import { __decorate, __metadata } from "tslib";
import { Component, Input, ViewChild } from "@angular/core";
import { DriverRound } from "../../../services/driver-round";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ViewDropoff } from "./view";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
let DriverDropOffList = class DriverDropOffList {
    constructor(DriverRound, Dialog) {
        this.DriverRound = DriverRound;
        this.Dialog = Dialog;
        this.loading = false;
        this.dropOffsDataSource = new MatTableDataSource([]);
        this.displayedColumns = [
            "add",
            "wasteFacility",
            "postcode",
            "description",
        ];
    }
    ngOnInit() {
        this.DriverRound.Round.subscribe((Round) => {
            this.dropOffsDataSource.data = Round.DropOffs;
            this.Round = Round;
        });
        this.setDataSourceAttributes();
        this.DriverRound.loading.subscribe((loading) => {
            this.loading = loading;
        });
    }
    applyDropOffsListFilter(event) {
        const searchValue = event.target.value;
        this.dropOffsDataSource.filter = searchValue.trim().toLowerCase();
    }
    setDataSourceAttributes() {
        const { filterPredicate } = this.dropOffsDataSource;
        this.dropOffsDataSource.filterPredicate = (dropOffs, filter) => {
            const searchableData = Object.assign(Object.assign({}, dropOffs.WasteFacility.address), this.Round.Consignments[dropOffs.consignmentId]);
            return filterPredicate(searchableData, filter);
        };
        this.dropOffsDataSource.sort = this.sort;
        this.dropOffsDataSource.paginator = this.paginator;
    }
    viewItem(DropOff) {
        let config = new MatDialogConfig();
        let Consignment = (!!this.Round.Consignments &&
            this.Round.Consignments[DropOff.consignmentId]) ||
            null;
        let Collection = (!!Consignment &&
            this.Round.Collections.filter((Collection) => Collection.collectionId == Consignment.collectionId).pop()) ||
            null;
        config.data = {
            Collection: Collection,
            DropOff: DropOff,
            Consignment: Consignment,
            Waste: (!!Consignment && Consignment.Waste) || null,
            DropOffSignatures: (!!DropOff.Signatures && DropOff.Signatures) || null,
        };
        config.disableClose = true;
        let Dialog = this.Dialog.open(ViewDropoff, config);
        Dialog.afterClosed().subscribe((ConsigneeCertificate) => {
            if (!!ConsigneeCertificate) {
                DropOff.Signatures = ConsigneeCertificate.DropOffSignatures;
                DropOff.ReceivedWaste = ConsigneeCertificate.ReceivedWaste;
                this.DriverRound.setRound(this.Round).then(() => this.DriverRound.saveRoundToLocalStorage());
            }
        });
    }
};
__decorate([
    Input(),
    __metadata("design:type", Date)
], DriverDropOffList.prototype, "date", void 0);
__decorate([
    ViewChild(MatSort, { static: true }),
    __metadata("design:type", MatSort)
], DriverDropOffList.prototype, "sort", void 0);
__decorate([
    ViewChild(MatPaginator, { static: true }),
    __metadata("design:type", MatPaginator)
], DriverDropOffList.prototype, "paginator", void 0);
DriverDropOffList = __decorate([
    Component({
        selector: "driver-drop-offs-list",
        template: require('./list.html'),
        styles: [require('./list.scss')],
    }),
    __metadata("design:paramtypes", [DriverRound,
        MatDialog])
], DriverDropOffList);
export { DriverDropOffList };
