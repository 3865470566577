import { __decorate, __metadata } from "tslib";
import { Injectable } from "@angular/core";
import { environment } from "../constant/environment";
import { Api } from "./api";
let DropOffApi = class DropOffApi {
    constructor(Api) {
        this.Api = Api;
    }
    list() {
        let endpoint = environment.drivers.endpoint() + "drop-offs";
        return this.Api.request(endpoint, {}, null, null);
    }
};
DropOffApi = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [Api])
], DropOffApi);
export { DropOffApi };
