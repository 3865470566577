import { __awaiter, __decorate, __metadata } from "tslib";
import { Component } from "@angular/core";
import { DriverRound } from "../../../services/driver-round";
import { Auth } from "../../../services/auth";
import { GPS } from "../../../services/gps";
let DriverTabs = class DriverTabs {
    constructor(DriverRound, Auth, GPS) {
        this.DriverRound = DriverRound;
        this.Auth = Auth;
        this.GPS = GPS;
    }
    ngOnInit() {
        return __awaiter(this, void 0, void 0, function* () {
            this.VehicleAuth = this.Auth.getVehicleAuth();
            this.DriverRound.load();
            this.GPS.permissionListener();
        });
    }
};
DriverTabs = __decorate([
    Component({
        selector: "driver-tabs",
        template: require('./component.html'),
        styles: [require('./component.scss')],
    }),
    __metadata("design:paramtypes", [DriverRound,
        Auth,
        GPS])
], DriverTabs);
export { DriverTabs };
