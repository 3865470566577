import { __decorate, __metadata } from "tslib";
import { Component, Input, ViewChild } from "@angular/core";
import { DriverRound } from "../../../services/driver-round";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ViewCollection } from "./view";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
let DriverCollectionList = class DriverCollectionList {
    constructor(DriverRound, Dialog) {
        this.DriverRound = DriverRound;
        this.Dialog = Dialog;
        this.loading = false;
        this.collectionsDataSource = new MatTableDataSource([]);
        this.displayedColumns = [
            "add",
            "company",
            "postcode",
            "description",
        ];
    }
    ngOnInit() {
        this.setDataSourceAttributes();
        this.DriverRound.Round.subscribe((Round) => {
            this.collectionsDataSource.data = Round.Collections;
            this.Round = Round;
        });
        this.DriverRound.loading.subscribe((loading) => {
            this.loading = loading;
        });
    }
    applyCollectionsListFilter(event) {
        const searchValue = event.target.value;
        this.collectionsDataSource.filter = searchValue.trim().toLowerCase();
    }
    setDataSourceAttributes() {
        const { filterPredicate } = this.collectionsDataSource;
        this.collectionsDataSource.filterPredicate = (carrierList, filter) => {
            const searchableData = Object.assign(Object.assign(Object.assign({}, carrierList), this.Round.Consignments[carrierList.consignmentId]), this.Round.Consignments[carrierList.consignmentId]
                .wasteRemovedFromAddress);
            return filterPredicate(searchableData, filter);
        };
        this.collectionsDataSource.sort = this.sort;
        this.collectionsDataSource.paginator = this.paginator;
    }
    viewItem(Collection) {
        let config = new MatDialogConfig();
        let Consignment = this.Round.Consignments[Collection.consignmentId];
        config.data = {
            Consignment: Consignment,
            Collection: Collection,
            Waste: Consignment.Waste,
            CollectionSignatures: (!!Collection.Signatures && Collection.Signatures) || null,
        };
        config.disableClose = true;
        let Dialog = this.Dialog.open(ViewCollection, config);
        Dialog.afterClosed().subscribe((CollectionSignatures) => {
            if (!!CollectionSignatures) {
                Collection.Signatures = CollectionSignatures;
                this.DriverRound.setRound(this.Round).then(() => this.DriverRound.saveRoundToLocalStorage());
            }
        });
    }
};
__decorate([
    Input(),
    __metadata("design:type", Date)
], DriverCollectionList.prototype, "date", void 0);
__decorate([
    ViewChild(MatSort, { static: true }),
    __metadata("design:type", MatSort)
], DriverCollectionList.prototype, "sort", void 0);
__decorate([
    ViewChild(MatPaginator, { static: true }),
    __metadata("design:type", MatPaginator)
], DriverCollectionList.prototype, "paginator", void 0);
DriverCollectionList = __decorate([
    Component({
        selector: "driver-collection-list",
        template: require('./list.html'),
        styles: [require('./list.scss')],
    }),
    __metadata("design:paramtypes", [DriverRound,
        MatDialog])
], DriverCollectionList);
export { DriverCollectionList };
