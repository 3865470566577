import { __decorate, __metadata, __param } from "tslib";
import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA, } from "@angular/material/dialog";
import { FormBuilder, Validators } from "@angular/forms";
import { SignatureFormFactory } from "../../../factories/signature";
import { ConsigneeCertificateDialog } from "../certificates/consignee";
import { DeclineModal } from "../decline/component";
let ViewDropoff = class ViewDropoff {
    constructor(Dialog, DialogRef, data) {
        this.Dialog = Dialog;
        this.DialogRef = DialogRef;
        this.data = data;
        this.loading = false;
    }
    ngOnInit() {
        const builder = new FormBuilder();
        this.Consignment = this.data.Consignment;
        this.Waste = this.data.Waste;
        this.Collection = this.data.Collection;
        this.DropOff = this.data.DropOff;
        let ConsigneeSignature = (!!this.data.Signatures && this.data.Signatures.Consignee) || null;
        let ReceivedWaste = this.DropOff.ReceivedWaste.map((waste) => builder.group({
            ewcCode: [waste.ewcCode, Validators.required],
            qty: [waste.qty, Validators.required],
            wasteOperationCode: [waste.wasteOperationCode, Validators.required],
        }));
        this.FormGroup = builder.group({
            Consignee: SignatureFormFactory.form(ConsigneeSignature),
            ReceivedWaste: builder.array(ReceivedWaste),
        });
        this.collected =
            !!this.Consignment.collectedTimestamp ||
                (!!this.Collection && !!this.Collection.collected);
        this.droppedOff = !!this.Consignment.droppedOffTimestamp;
        this.declined =
            !!this.Consignment.collectionDeclinedTimestamp ||
                !!this.Consignment.dropOffDeclinedTimestamp;
    }
    onSubmit() {
        const formData = this.FormGroup.getRawValue();
        const ConsigneeCertificate = {
            DropOffSignatures: { Consignee: formData.Consignee },
            ReceivedWaste: formData.ReceivedWaste,
        };
        this.DialogRef.close(ConsigneeCertificate);
    }
    declineDropOff() {
        // open the modal
        let config = new MatDialogConfig();
        config.disableClose = true;
        config.data = "drop-off";
        let DialogRef = this.Dialog.open(DeclineModal, config);
        DialogRef.afterClosed().subscribe((Decline) => {
            !!Decline &&
                this.DialogRef.close({ DropOffSignatures: { decline: Decline } });
        });
    }
    consigneeCertificate() {
        let config = new MatDialogConfig();
        config.disableClose = true;
        config.data = {
            DropOff: this.DropOff,
            FormGroup: this.FormGroup.get("Consignee"),
        };
        this.Dialog.open(ConsigneeCertificateDialog, config);
    }
};
ViewDropoff = __decorate([
    Component({
        selector: "view-drop-off",
        template: require('./view.html'),
        styles: [require('./view.scss')],
    }),
    __param(2, Inject(MAT_DIALOG_DATA)),
    __metadata("design:paramtypes", [MatDialog,
        MatDialogRef, Object])
], ViewDropoff);
export { ViewDropoff };
