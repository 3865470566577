import { __decorate, __metadata } from "tslib";
import { Component, Input } from '@angular/core';
let ConsignmentSummary = class ConsignmentSummary {
};
__decorate([
    Input(),
    __metadata("design:type", Object)
], ConsignmentSummary.prototype, "Consignment", void 0);
ConsignmentSummary = __decorate([
    Component({
        selector: 'consignment-summary',
        template: require('./component.html'),
        styles: [require('./component.scss')]
    })
], ConsignmentSummary);
export { ConsignmentSummary };
