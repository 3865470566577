var Rounds_1;
import { __decorate, __metadata } from "tslib";
import { Injectable } from "@angular/core";
import { environment } from "../constant/environment";
import { LocalStorageService } from "angular-2-local-storage";
import { BehaviorSubject } from "rxjs";
import { Api } from "./api";
import { Auth } from "./auth";
import { AwSnackBar } from "./aw-snack-bar";
import { ConsignmentApi } from "./consignment-api";
import { CollectionApi } from "./collection-api";
import { DropOffApi } from "./drop-off-api";
let Rounds = Rounds_1 = class Rounds {
    constructor(Api, Auth, LocalStorageService, ConsignmentApi, CollectionApi, DropOffApi, snackBar) {
        this.Api = Api;
        this.Auth = Auth;
        this.LocalStorageService = LocalStorageService;
        this.ConsignmentApi = ConsignmentApi;
        this.CollectionApi = CollectionApi;
        this.DropOffApi = DropOffApi;
        this.snackBar = snackBar;
        this.loading = new BehaviorSubject(false);
        this.unsavedSignatures = new BehaviorSubject(0);
    }
    countUnsavedSignatures() {
        return new Promise((resolve, reject) => {
            try {
                this.initialiseRounds().then((Rounds) => {
                    let count = 0;
                    let keys = Object.keys(Rounds);
                    keys.forEach((key) => {
                        let Round = Rounds[key];
                        let collectedSignatures = Round.Collections.filter((Collection) => !!Collection.Signatures);
                        let droppedOffSignatures = Round.DropOffs.filter((DropOff) => !!DropOff.Signatures);
                        count += [...collectedSignatures, ...droppedOffSignatures].length;
                    });
                    this.unsavedSignatures.next(count);
                    resolve(count);
                }, (error) => reject(error));
            }
            catch (error) {
                reject(new Error(error));
            }
        });
    }
    getRounds() {
        return new Promise((resolve, reject) => {
            try {
                this.initialiseRounds().then((Rounds) => resolve(Rounds), (error) => reject(error));
            }
            catch (error) {
                reject(new Error(error));
            }
        });
    }
    loadRound(useApi) {
        return new Promise((resolve, reject) => {
            try {
                this.initialiseRounds().then((Rounds) => {
                    let jwt = this.Auth.getJwt(), stored = Rounds.hasOwnProperty(jwt);
                    if (!useApi && stored) {
                        resolve(Rounds[jwt]);
                    }
                    else {
                        this.loadRoundFromApi().then((Round) => resolve(Round), (error) => reject(error));
                    }
                }, (error) => reject(error));
            }
            catch (error) {
                reject(new Error(error));
            }
        });
    }
    loadRoundFromApi() {
        return new Promise((resolve, reject) => {
            try {
                let Round = {
                    Collections: null,
                    DropOffs: null,
                    Consignments: null,
                };
                let requests = [];
                requests.push(this.ConsignmentApi.driverList()
                    .catch((error) => reject(error))
                    .then((Consignments) => {
                    Round.Consignments = Consignments;
                    return Consignments;
                }));
                requests.push(this.CollectionApi.list()
                    .catch((error) => reject(error))
                    .then((Collections) => {
                    Round.Collections = Collections;
                    return Collections;
                }));
                requests.push(this.DropOffApi.list()
                    .catch((error) => reject(error))
                    .then((DropOffs) => {
                    Round.DropOffs = DropOffs;
                    return DropOffs;
                }));
                Promise.all(requests)
                    //Check responses - offline check
                    .then((responses) => {
                    responses = responses.filter((response) => !!response);
                    let success = requests.length == responses.length;
                    return success ? Promise.resolve() : Promise.reject("Offline mode");
                })
                    //Populate received waste table
                    .then(() => {
                    Round.DropOffs.forEach((dropOff) => {
                        let reportedWaste = Round.Consignments[dropOff.consignmentId].Waste;
                        dropOff.ReceivedWaste = dropOff.hasOwnProperty("receivedWaste")
                            ? Object.assign([], dropOff["receivedWaste"])
                            : this.reportedToReceivedWaste(reportedWaste);
                        delete dropOff["receivedWaste"];
                    });
                })
                    //save to local storage
                    .then(() => this.saveRoundToLocalStorage(Round))
                    .then(() => resolve(Round))
                    .catch((error) => reject(error));
            }
            catch (error) {
                reject(new Error(error));
            }
        });
    }
    reportedToReceivedWaste(reportedWaste) {
        return reportedWaste.map((waste) => ({
            ewcCode: waste.ewcCode.code,
            qty: waste.qty,
            wasteOperationCode: null,
        }));
    }
    saveRoundToLocalStorage(Round) {
        let VehicleAuth = this.Auth.getVehicleAuth();
        Round.jwt = VehicleAuth.jwt;
        Round.vehicleId = VehicleAuth.vehicleId;
        Round.roundDate = VehicleAuth.date;
        this.Rounds[VehicleAuth.jwt] = Round;
        return this.saveRoundsToLocalStorage();
    }
    uploadSignatures() {
        return new Promise((resolve, reject) => {
            try {
                this.getRounds().then((Rounds) => {
                    let roundKeys = Object.keys(Rounds);
                    let updates = [];
                    roundKeys.forEach((key) => {
                        const Round = Rounds[key];
                        Round.Collections.forEach((Collection) => {
                            !!Collection.Signatures &&
                                updates.push(this.createCollectionUpdate(Round, Collection).catch((error) => reject(error)));
                        });
                        Round.DropOffs.forEach((DropOff) => {
                            !!DropOff.Signatures &&
                                updates.push(this.createDropOffUpdate(Round, DropOff).catch((error) => reject(error)));
                        });
                    });
                    Promise.all(updates)
                        .then((responses) => {
                        responses = responses.filter((response) => !!response);
                        let success = updates.length == responses.length;
                        responses.length &&
                            this.snackBar.handleSuccess("Uploaded " + updates.length + " signature(s).");
                        // clean up
                        success && this.saveRoundsToLocalStorage();
                    }, (error) => reject(error))
                        .then(() => resolve(), (error) => reject(error));
                }, (error) => reject(error));
            }
            catch (error) {
                reject(error);
            }
        });
    }
    initialiseRounds() {
        return new Promise((resolve, reject) => {
            try {
                if (!!this.Rounds) {
                    resolve(this.Rounds);
                }
                else {
                    this.loadRoundsFromLocalStorage().then((Rounds) => resolve(Rounds), (error) => reject(error));
                }
            }
            catch (error) {
                reject(new Error(error));
            }
        });
    }
    createCollectionUpdate(Round, Collection) {
        try {
            return this.uploadCollection(Round.jwt, Collection).then((result) => {
                let DropOff = Round.DropOffs.filter((DropOff) => DropOff.collectionId == Collection.collectionId).pop();
                if (result.hasOwnProperty("collectedTimestamp")) {
                    Collection.collectedTimestamp = result.collectedTimestamp;
                    !!DropOff &&
                        (DropOff.collectedTimestamp = Collection.collectedTimestamp);
                }
                if (result.hasOwnProperty("declinedTimestamp")) {
                    Collection.declinedTimestamp = result.declinedTimestamp;
                    !!DropOff &&
                        (DropOff.declinedTimestamp = Collection.declinedTimestamp);
                }
                delete Collection.Signatures;
            });
        }
        catch (error) {
            this.snackBar.handleError(new Error(`
          Could not upload signatures for collection: ${Collection.collectionId}`));
        }
    }
    createDropOffUpdate(Round, DropOff) {
        try {
            return this.uploadDropOff(Round.jwt, DropOff).then((result) => {
                if (result.hasOwnProperty("droppedOffTimestamp")) {
                    DropOff.droppedOffTimestamp = result.droppedOffTimestamp;
                }
                if (result.hasOwnProperty("declinedTimestamp")) {
                    DropOff.declinedTimestamp = result.declinedTimestamp;
                }
                delete DropOff.Signatures;
            });
        }
        catch (error) {
            this.snackBar.handleError(new Error(`Could not upload signatures for drop-off: ${DropOff.dropOffId}`));
        }
    }
    uploadCollection(jwt, Collection) {
        return new Promise((resolve, reject) => {
            try {
                const images = Rounds_1.getCollectionUpdateImages(Collection);
                let endpoint = environment.drivers.endpoint() +
                    "collections/" +
                    Collection.collectionId;
                let options = {
                    body: Rounds_1.getCollectionUpdateBody(Collection),
                };
                this.Api.request(endpoint, options, jwt, "PUT").then((result) => resolve(this.uploadSignatureImages(images, result)), (error) => reject(error));
            }
            catch (error) {
                reject(error);
            }
        });
    }
    uploadDropOff(jwt, DropOff) {
        return new Promise((resolve, reject) => {
            try {
                const images = Rounds_1.getDropOffUpdateImages(DropOff);
                let endpoint = `${environment.drivers.endpoint()}drop-offs/${DropOff.dropOffId}`;
                let options = {
                    body: Rounds_1.getDropOffUpdateBody(DropOff),
                };
                this.Api.request(endpoint, options, jwt, "PUT").then((result) => resolve(this.uploadSignatureImages(images, result)), (error) => reject(error));
            }
            catch (error) {
                reject(error);
            }
        });
    }
    cleanUpRounds() {
        return new Promise((resolve, reject) => {
            try {
                let roundKeys = Object.keys(this.Rounds);
                roundKeys.forEach((key) => {
                    let isLoggedInRound = this.Rounds[key].jwt === this.Auth.getJwt();
                    if (!isLoggedInRound) {
                        // only return collections that are there or have
                        this.Rounds[key].Collections = this.Rounds[key].Collections.filter((Collection) => !!Collection.Signatures);
                        this.Rounds[key].DropOffs = this.Rounds[key].DropOffs.filter((DropOff) => !!DropOff.Signatures);
                        // clear round if possible
                        !this.Rounds[key].Collections.length &&
                            !this.Rounds[key].DropOffs.length &&
                            delete this.Rounds[key];
                    }
                });
                resolve();
            }
            catch (error) {
                reject(error);
            }
        });
    }
    loadRoundsFromLocalStorage() {
        return new Promise((resolve, reject) => {
            try {
                let storedRounds = this.LocalStorageService.get("Rounds");
                this.Rounds = !!storedRounds ? storedRounds : {};
                resolve(this.Rounds);
            }
            catch (error) {
                reject(error);
            }
        });
    }
    saveRoundsToLocalStorage() {
        return new Promise((resolve, reject) => {
            try {
                this.cleanUpRounds()
                    .then(() => this.LocalStorageService.set("Rounds", this.Rounds))
                    .then(() => this.countUnsavedSignatures())
                    .then(() => resolve())
                    .catch((error) => reject(error));
            }
            catch (error) {
                reject(error);
            }
        });
    }
    uploadSignatureImages(images, result) {
        return !!images
            ? new Promise((resolve, reject) => {
                try {
                    let uploads = [];
                    Object.keys(images).map((name) => {
                        var _a, _b;
                        if (((_a = images[name]) === null || _a === void 0 ? void 0 : _a.signature) && result.putUrls[name].signature) {
                            uploads.push(this.uploadSignatureImage(result.putUrls[name].signature, images[name].signature));
                        }
                        if (((_b = images[name]) === null || _b === void 0 ? void 0 : _b.signature) &&
                            result.putUrls[name].wasteImage) {
                            uploads.push(this.uploadSignatureImage(result.putUrls[name].wasteImage, images[name].wasteImage));
                        }
                    });
                    Promise.all(uploads).then(() => resolve(result), (error) => reject(error));
                }
                catch (error) {
                    reject(error);
                }
            })
            : result;
    }
    uploadSignatureImage(pushUrl, image) {
        return new Promise((resolve, reject) => {
            try {
                this.Api.s3PutObject(pushUrl, image).then(() => resolve(), (error) => reject(error));
            }
            catch (error) {
                reject(error);
            }
        });
    }
    static getCollectionUpdateImages(Collection) {
        if (Collection.Signatures.hasOwnProperty("decline")) {
            return null;
        }
        const CarrierSignature = Collection.Signatures.Carrier;
        const ConsignorSignature = Collection.Signatures.Consignor;
        let images = {
            carrier: {
                signature: (CarrierSignature === null || CarrierSignature === void 0 ? void 0 : CarrierSignature.signature)
                    ? CarrierSignature.signature
                    : null,
                wasteImage: (CarrierSignature === null || CarrierSignature === void 0 ? void 0 : CarrierSignature.wasteImage)
                    ? CarrierSignature.wasteImage
                    : null,
            },
            consignor: {
                signature: (ConsignorSignature === null || ConsignorSignature === void 0 ? void 0 : ConsignorSignature.signature)
                    ? ConsignorSignature.signature
                    : null,
                wasteImage: (ConsignorSignature === null || ConsignorSignature === void 0 ? void 0 : ConsignorSignature.wasteImage)
                    ? ConsignorSignature.wasteImage
                    : null,
            },
        };
        if (Collection.Signatures.DangerousGoods) {
            const DangerousGoods = Collection.Signatures.DangerousGoods;
            const DG = {
                dangerousGoods: {
                    wasteImage: !!DangerousGoods.sealNumberImage
                        ? DangerousGoods.sealNumberImage
                        : null,
                },
            };
            images = Object.assign(Object.assign({}, images), DG);
        }
        return images;
    }
    static getDropOffUpdateImages(DropOff) {
        if (DropOff.Signatures.hasOwnProperty("decline")) {
            return null;
        }
        const Consignee = DropOff.Signatures.Consignee;
        return {
            consignee: {
                signature: !!Consignee.signature ? Consignee.signature : null,
                wasteImage: !!Consignee.wasteImage ? Consignee.wasteImage : null,
            },
        };
    }
    static getCollectionUpdateBody(Collection) {
        var _a;
        let body = Collection.Signatures;
        if (!Collection.Signatures.hasOwnProperty("decline")) {
            const Carrier = Collection.Signatures.Carrier;
            const Consignor = Collection.Signatures.Consignor;
            body = {
                Carrier: Object.assign({
                    signatureImageType: !!Carrier.signature
                        ? Carrier.signature.type
                        : null,
                    wasteImageType: !!Carrier.wasteImage
                        ? Carrier.wasteImage.type
                        : null,
                }, Carrier),
                Consignor: Object.assign({
                    signatureImageType: !!Consignor.signature
                        ? Consignor.signature.type
                        : null,
                    wasteImageType: !!Consignor.wasteImage
                        ? Consignor.wasteImage.type
                        : null,
                }, Consignor),
            };
            if ((_a = Collection === null || Collection === void 0 ? void 0 : Collection.Signatures) === null || _a === void 0 ? void 0 : _a.DangerousGoods) {
                const DangerousGoods = Collection.Signatures.DangerousGoods;
                body = Object.assign(Object.assign({}, body), { DangerousGoods: Object.assign({
                        signatureImageType: null,
                        wasteImageType: !!DangerousGoods.sealNumberImage
                            ? DangerousGoods.sealNumberImage.type
                            : null,
                    }, DangerousGoods) });
                delete body.DangerousGoods.sealNumberImage;
            }
            delete body.Carrier.signature;
            delete body.Carrier.wasteImage;
            delete body.Consignor.signature;
            delete body.Consignor.wasteImage;
        }
        return body;
    }
    static getDropOffUpdateBody(DropOff) {
        let body;
        if (!DropOff.Signatures.hasOwnProperty("decline")) {
            const Consignee = DropOff.Signatures.Consignee;
            body = {
                ReceivedWaste: DropOff.ReceivedWaste,
                Consignee: Object.assign({
                    signatureImageType: !!Consignee.signature
                        ? Consignee.signature.type
                        : null,
                    wasteImageType: !!Consignee.wasteImage
                        ? Consignee.wasteImage.type
                        : null,
                }, Consignee),
            };
            delete body.Consignee.signature;
            delete body.Consignee.wasteImage;
        }
        else {
            body = DropOff.Signatures;
        }
        return body;
    }
};
Rounds = Rounds_1 = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [Api,
        Auth,
        LocalStorageService,
        ConsignmentApi,
        CollectionApi,
        DropOffApi,
        AwSnackBar])
], Rounds);
export { Rounds };
