import { __decorate } from "tslib";
import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, } from "@angular/core";
import { HttpModule } from "@angular/http";
import { LocalStorageModule } from "angular-2-local-storage";
import { ConsignmentDetails } from "../components/consignments/details/component";
import { EditRecordedWaste } from "../components/consignments/edit-recorded-waste/component";
import { RecordedWasteTable } from "../components/consignments/recorded-waste-table/component";
import { ConsignmentSummary } from "../components/consignments/summary/component";
import { ConsignmentWasteTable } from "../components/consignments/waste-table/component";
import { AnyWasteMaterialModule } from "../modules/material.module";
import { routing, appRouting } from "./routing.module";
import { AppComponent } from "./component";
import { AngularSignaturePadModule } from "@almothafar/angular-signature-pad";
import { AddressSummary } from "../components/address/address";
import { DriverTabs } from "../components/driver/tabs/component";
import { DriverRound } from "../services/driver-round";
import { CollectionApi } from "../services/collection-api";
import { DropOffApi } from "../services/drop-off-api";
import { DriverCollectionList } from "../components/driver/collections/list";
import { DriverDropOffList } from "../components/driver/drop-offs/list";
import { ViewCollection } from "../components/driver/collections/view";
import { Map } from "../components/driver/map/map";
import { Signature } from "../components/signature-modal/component";
import { CarrierCertificate } from "../components/driver/certificates/carrier";
import { ConsignorCertificate } from "../components/driver/certificates/consignor";
import { ConsigneeCertificateDialog } from "../components/driver/certificates/consignee";
import { DangerousGoodsDialog } from "../components/driver/dangerous-goods/component";
import { DriverGuard } from "../services/driver-guard";
import { DriverLogin } from "../components/driver/login/component";
import { ViewDropoff } from "../components/driver/drop-offs/view";
import { DriverSync } from "../components/driver/sync/component";
import { DeclineModal } from "../components/driver/decline/component";
import { ConsignmentApi } from "../services/consignment-api";
import { AwSnackBar } from "../services/aw-snack-bar";
import { Auth } from "../services/auth";
import { GPS } from "../services/gps";
import { Api } from "../services/api";
import { Rounds } from "../services/rounds";
import { DirectivesModule } from "../modules/directives/module";
let AppModule = class AppModule {
};
AppModule = __decorate([
    NgModule({
        imports: [
            AnyWasteMaterialModule,
            DirectivesModule,
            AngularSignaturePadModule,
            HttpModule,
            routing,
            LocalStorageModule.withConfig({
                prefix: "AnyWaste",
                storageType: "localStorage",
            }),
        ],
        declarations: [
            AppComponent,
            ConsignmentSummary,
            ConsignmentDetails,
            DangerousGoodsDialog,
            ConsignmentWasteTable,
            RecordedWasteTable,
            EditRecordedWaste,
            AddressSummary,
            DriverLogin,
            DriverTabs,
            DriverCollectionList,
            DriverDropOffList,
            DriverSync,
            DeclineModal,
            Map,
            ViewCollection,
            ViewDropoff,
            Signature,
            CarrierCertificate,
            ConsignorCertificate,
            ConsigneeCertificateDialog,
        ],
        entryComponents: [
            EditRecordedWaste,
            DeclineModal,
            ViewCollection,
            ViewDropoff,
            Signature,
            CarrierCertificate,
            ConsignorCertificate,
            ConsigneeCertificateDialog,
        ],
        providers: [
            { provide: LOCALE_ID, useValue: "en-GB" },
            appRouting,
            Auth,
            Api,
            Rounds,
            AwSnackBar,
            ConsignmentApi,
            DriverRound,
            CollectionApi,
            DropOffApi,
            DriverGuard,
            GPS,
        ],
        bootstrap: [AppComponent],
        schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
    })
], AppModule);
export { AppModule };
