import { __decorate, __metadata } from "tslib";
import { Injectable } from "@angular/core";
import { LocalStorageService } from "angular-2-local-storage";
import { Router } from "@angular/router";
import { AwSnackBar } from "./aw-snack-bar";
let Auth = class Auth {
    constructor(LocalStorageService, Router, snackBar) {
        this.LocalStorageService = LocalStorageService;
        this.Router = Router;
        this.snackBar = snackBar;
    }
    getVehicleAuth() {
        return this.VehicleAuth;
    }
    getJwt() {
        return (!!this.VehicleAuth && this.VehicleAuth.jwt) || null;
    }
    getDriverName() {
        return (!!this.VehicleAuth && this.VehicleAuth.driverName) || null;
    }
    getVehicleId() {
        return (!!this.VehicleAuth && this.VehicleAuth.vehicleId) || null;
    }
    getLoggedInRoundId() {
        return this.VehicleAuth.vehicleId + "." + this.VehicleAuth.date;
    }
    isLoggedIn() {
        return !!this.VehicleAuth && !!this.VehicleAuth.vehicleId;
    }
    loginFromLocalStorage() {
        return new Promise((resolve, reject) => {
            let stored = this.LocalStorageService.get("VehicleAuth");
            !!stored && this.setVehicleAuth(stored);
            if (this.isLoggedIn()) {
                resolve();
            }
            else {
                reject();
            }
        });
    }
    logout() {
        this.VehicleAuth = null;
        this.LocalStorageService.remove("VehicleAuth");
        this.Router.navigate(["/login"]);
    }
    setAuthFromLogin(login, vehicleAuths) {
        return this.extractVehicleAuth(vehicleAuths, login.driverName);
    }
    extractVehicleAuth(vehicleAuths, driverName) {
        if (vehicleAuths.length == 1) {
            this.setVehicleAuth(vehicleAuths.pop(), driverName);
        }
        else {
            this.snackBar.handleError(new Error("There is an issue with your vehicle details, please check and try again."));
        }
        return this.isLoggedIn();
    }
    setVehicleAuth(VehicleAuth, driverName) {
        this.VehicleAuth = VehicleAuth;
        !!driverName && (this.VehicleAuth.driverName = driverName);
        this.LocalStorageService.set("VehicleAuth", VehicleAuth);
        return true;
    }
};
Auth = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [LocalStorageService,
        Router,
        AwSnackBar])
], Auth);
export { Auth };
