import { __decorate } from "tslib";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { Injectable } from "@angular/core";
let AwSnackBar = class AwSnackBar extends MatSnackBar {
    handleSuccess(message) {
        let config = new MatSnackBarConfig();
        // config.extraClasses = ['success'];
        return this.snackBar(config, message);
    }
    handleError(error) {
        let config = new MatSnackBarConfig();
        // config.extraClasses = ['error'];
        return this.snackBar(config, error.toString());
    }
    snackBar(config, message) {
        config.duration = 2000 + message.length * 50;
        return new Promise((resolve) => this.open(message, "close", config)
            .afterOpened()
            .subscribe(() => resolve()));
    }
};
AwSnackBar = __decorate([
    Injectable()
], AwSnackBar);
export { AwSnackBar };
