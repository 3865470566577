import { __awaiter, __decorate, __metadata } from "tslib";
import { Injectable } from "@angular/core";
import { Api } from "./api";
import { Geolocation } from "@capacitor/geolocation";
import { BehaviorSubject } from "rxjs";
import { Capacitor, registerPlugin } from "@capacitor/core";
import { environment } from "../constant/environment";
import { App } from "@capacitor/app";
let GPS = class GPS {
    constructor(Api) {
        this.Api = Api;
        this.BackgroundGeolocation = registerPlugin("BackgroundGeolocation");
        this.restart = new BehaviorSubject(true);
        this.coordinates = new BehaviorSubject({});
        this.watchId = null;
        this.isActive = true;
        this.stopTracker = () => __awaiter(this, void 0, void 0, function* () {
            App.removeAllListeners();
            const opt = { id: yield this.watchId };
            this.BackgroundGeolocation.removeWatcher(opt);
            this.watchId = null;
        });
        this.restartTracker = () => __awaiter(this, void 0, void 0, function* () {
            // Restart tracker if error / permission error
            if (this.watchId !== null) {
                yield this.stopTracker();
            }
            this.startTracker();
        });
        this.getCurrentLocation = () => {
            return this.coordinates;
        };
    }
    permissionListener() {
        return __awaiter(this, void 0, void 0, function* () {
            if (Capacitor.getPlatform() === "web") {
                this.initialiseCoords();
            }
            else {
                const permission = yield Geolocation.requestPermissions({
                    permissions: [],
                });
                if ((!this.watchId && !permission) ||
                    (this.watchId && permission.location === "denied")) {
                    this.restartPermissions();
                }
                // Watching the app state to see whether it is active or currently in background
                App.addListener("appStateChange", ({ isActive }) => __awaiter(this, void 0, void 0, function* () {
                    const permission = yield Geolocation.requestPermissions({
                        permissions: [],
                    });
                    if ((isActive && !this.watchId && !permission) ||
                        (isActive && this.watchId && permission.location === "denied")) {
                        // if the app is open and the gps watcher exists but no permission open permissions settings
                        this.restartPermissions();
                    }
                    else if (isActive &&
                        !this.watchId &&
                        permission.location === "granted") {
                        // if the app is open and the gps watcher exists but no update has been made in the last 5 mins restart tracker
                        this.restart.next(true);
                    }
                }));
                yield this.restart.subscribe((restart) => __awaiter(this, void 0, void 0, function* () {
                    if (restart) {
                        this.restartTracker();
                    }
                }));
            }
        });
    }
    initialiseCoords() {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            let currentGPS;
            currentGPS = yield Geolocation.getCurrentPosition();
            currentGPS = {
                latitude: (_a = currentGPS === null || currentGPS === void 0 ? void 0 : currentGPS.coords) === null || _a === void 0 ? void 0 : _a.latitude,
                longitude: (_b = currentGPS === null || currentGPS === void 0 ? void 0 : currentGPS.coords) === null || _b === void 0 ? void 0 : _b.longitude,
                time: currentGPS.timestamp,
            };
            this.lastUpdatedPosition = currentGPS;
            this.coordinates.next(currentGPS);
        });
    }
    restartPermissions() {
        // Flag to restart the tracker once permissions have been applied
        this.restart.next(true);
        this.BackgroundGeolocation.openSettings();
    }
    startTracker() {
        return __awaiter(this, void 0, void 0, function* () {
            this.restart.next(false);
            this.getDetails();
            this.initialiseCoords();
            if (this.watchId !== null) {
                this.stopTracker();
            }
            this.watchId = this.BackgroundGeolocation.addWatcher({
                backgroundMessage: "Anywaste driver location is currently sharing.",
                backgroundTitle: "AnyWaste GPS on",
                requestPermissions: true,
                stale: false,
                distanceFilter: 50,
            }, (location, error) => __awaiter(this, void 0, void 0, function* () {
                if (error) {
                    if (error.code === "NOT_AUTHORIZED") {
                        if (window.confirm("This app needs your location, " +
                            "but does not have permission.\n\n" +
                            "Open settings now?")) {
                            this.restartPermissions();
                        }
                    }
                    console.error("ERROR: ", error);
                }
                this.coordinates.next(Object.assign(Object.assign({}, location), { time: Date.now() }));
            }));
            if (this.watchId) {
                this.updateTimer = setInterval(this.checkForUpdates.bind(this), 60000);
            }
            else if (this.watchId === null) {
                clearInterval(this.updateTimer);
            }
        });
    }
    checkForUpdates() {
        var _a, _b, _c, _d, _e, _f;
        if (((_a = this.lastUpdatedPosition) === null || _a === void 0 ? void 0 : _a.latitude) !==
            ((_c = (_b = this.coordinates) === null || _b === void 0 ? void 0 : _b.value) === null || _c === void 0 ? void 0 : _c.latitude) &&
            ((_d = this.lastUpdatedPosition) === null || _d === void 0 ? void 0 : _d.longitude) !== ((_f = (_e = this.coordinates) === null || _e === void 0 ? void 0 : _e.value) === null || _f === void 0 ? void 0 : _f.longitude)) {
            this.pushGPSUpdates();
        }
        // }
    }
    pushGPSUpdates() {
        var _a, _b, _c, _d, _e, _f;
        return __awaiter(this, void 0, void 0, function* () {
            // Cannot send request without organsationID or vehicleId as they are dynamodb primary keys
            if (this.organisationId && this.vehicleId && this.coordinates.value) {
                const options = {
                    body: {
                        vehicleId: this.vehicleId,
                        organisationId: this.organisationId,
                        gps: {
                            latitude: (_b = (_a = this.coordinates) === null || _a === void 0 ? void 0 : _a.value) === null || _b === void 0 ? void 0 : _b.latitude,
                            longitude: (_d = (_c = this.coordinates) === null || _c === void 0 ? void 0 : _c.value) === null || _d === void 0 ? void 0 : _d.longitude,
                            time: (_f = (_e = this.coordinates) === null || _e === void 0 ? void 0 : _e.value) === null || _f === void 0 ? void 0 : _f.time,
                        },
                    },
                };
                try {
                    yield this.update(this.vehicleId, options);
                    this.lastUpdatedPosition = {
                        latitude: this.coordinates.value.latitude,
                        longitude: this.coordinates.value.longitude,
                        time: this.coordinates.value.time,
                    };
                }
                catch (error) {
                    return console.error(error);
                }
            }
        });
    }
    update(vehicleId, options) {
        let endpoint = `${environment.drivers.endpoint()}tracking/${vehicleId}`;
        return this.Api.request(endpoint, options, JSON.parse(localStorage.getItem("AnyWaste.VehicleAuth")).jwt, "PATCH");
    }
    getDetails() {
        const authData = JSON.parse(localStorage.getItem("AnyWaste.VehicleAuth"));
        // If no data get details from localstorage
        if (!this.organisationId) {
            this.organisationId = authData.organisationId;
        }
        if (!this.vehicleId) {
            this.vehicleId = authData.vehicleId;
        }
    }
};
GPS = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [Api])
], GPS);
export { GPS };
