import { __decorate, __metadata } from "tslib";
import { Component, ViewEncapsulation } from "@angular/core";
import { Auth } from "../services/auth";
import { GPS } from "../services/gps";
let AppComponent = class AppComponent {
    constructor(Auth, GPS) {
        this.Auth = Auth;
        this.GPS = GPS;
    }
    driverLoggedIn() {
        return this.Auth.isLoggedIn();
    }
    driverLogout() {
        this.GPS.stopTracker();
        this.Auth.logout();
    }
};
AppComponent = __decorate([
    Component({
        selector: "anywaste-app",
        template: require('./component.html'),
        styles: [require('./component.scss')],
        encapsulation: ViewEncapsulation.None,
    }),
    __metadata("design:paramtypes", [Auth, GPS])
], AppComponent);
export { AppComponent };
