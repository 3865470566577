import { __decorate, __metadata } from "tslib";
import { Component } from "@angular/core";
import { DriverRound } from "../../../services/driver-round";
import { Auth } from "../../../services/auth";
import { AwSnackBar } from "../../../services/aw-snack-bar";
import { Rounds } from "../../../services/rounds";
let DriverSync = class DriverSync {
    constructor(Auth, Rounds, DriverRound, snackBar) {
        this.Auth = Auth;
        this.Rounds = Rounds;
        this.DriverRound = DriverRound;
        this.snackBar = snackBar;
        this.loading = false;
        this.Subscriptions = [];
    }
    ngOnInit() {
        this.Subscriptions.push(this.Rounds.unsavedSignatures.subscribe((count) => (this.unsavedSignatures = count)));
    }
    ngOnDestroy() {
        this.Subscriptions.map((Subscription) => Subscription.unsubscribe());
    }
    isLoggedIn() {
        return this.Auth.isLoggedIn();
    }
    uploadSignatures() {
        this.loading = true;
        this.Rounds.uploadSignatures()
            .then(() => this.DriverRound.load(true))
            .then(() => (this.loading = false))
            .catch((error) => this.handleError(error));
    }
    handleError(error) {
        this.loading = false;
        this.snackBar.handleError(error);
    }
};
DriverSync = __decorate([
    Component({
        selector: "driver-sync",
        template: require('./component.html'),
        styles: [require('./component.scss')],
    }),
    __metadata("design:paramtypes", [Auth,
        Rounds,
        DriverRound,
        AwSnackBar])
], DriverSync);
export { DriverSync };
