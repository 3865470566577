import { RouterModule } from "@angular/router";
import { DriverTabs } from "../components/driver/tabs/component";
import { DriverGuard } from "../services/driver-guard";
import { DriverLogin } from "../components/driver/login/component";
const routes = [
    {
        path: "login",
        component: DriverLogin,
        canActivate: [DriverGuard],
    },
    {
        path: "dashboard",
        component: DriverTabs,
        canActivate: [DriverGuard],
    },
    { path: "**", redirectTo: "login", pathMatch: "full" },
];
export const appRouting = [];
export const routing = RouterModule.forRoot(routes);
