import { __decorate, __metadata, __param } from "tslib";
import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig, } from "@angular/material/dialog";
import { FormBuilder } from "@angular/forms";
import { DangerousGoodsDialog } from "../dangerous-goods/component";
import { SignatureFormFactory } from "../../../factories/signature";
import { CarrierCertificate } from "../certificates/carrier";
import { ConsignorCertificate } from "../certificates/consignor";
import { Auth } from "../../../services/auth";
import { DeclineModal } from "../decline/component";
import { dangerousGoodsCodes } from "../../../constant/waste-codes";
let ViewCollection = class ViewCollection {
    constructor(Dialog, DialogRef, fb, Auth, data) {
        this.Dialog = Dialog;
        this.DialogRef = DialogRef;
        this.fb = fb;
        this.Auth = Auth;
        this.data = data;
        this.loading = false;
        this.removedFromAndProducerAddressTheSame = false;
        this.dangerousGoods = true;
    }
    ngOnInit() {
        var _a;
        this.Consignment = this.data.Consignment;
        this.Collection = this.data.Collection;
        this.Waste = this.data.Waste;
        this.WasteFacility = this.data.WasteFacility;
        this.dangerousGoods = (_a = this.data.Waste) === null || _a === void 0 ? void 0 : _a.some((item) => {
            var _a;
            return dangerousGoodsCodes.includes((_a = item.ewcCode) === null || _a === void 0 ? void 0 : _a.code);
        });
        let CarrierSignature = (!!this.data.Signatures &&
            this.data.Signatures.Carrier) || {
            name: this.Auth.getDriverName(),
        };
        let ConsignorSignature = (!!this.data.Signatures && this.data.Signatures.Consignor) || null;
        let fb = new FormBuilder();
        this.FormGroup = fb.group({
            DangerousGoods: fb.group({
                shippingMarks: [null],
                sealNumbers: [null],
                sealNumberImage: [null],
                containerId: [null],
                vehicleType: [null],
                containerType: [null],
                containerSize: [null],
                emptyContainerWeight: [null],
                fullContainerWeight: [null],
                receivingRemarks: [null],
            }),
            Carrier: SignatureFormFactory.form(CarrierSignature),
            Consignor: SignatureFormFactory.form(ConsignorSignature),
        });
        this.collected = !!this.Consignment.collectedTimestamp;
        this.declined = !!this.Consignment.collectionDeclinedTimestamp;
        let wasteProducerAddress = JSON.stringify(this.Consignment.wasteProducerAddress);
        let wasteRemovedFromAddress = JSON.stringify(this.Consignment.wasteRemovedFromAddress);
        this.removedFromAndProducerAddressTheSame =
            wasteProducerAddress == wasteRemovedFromAddress;
    }
    onSubmit() {
        var _a, _b;
        let value = this.FormGroup.getRawValue();
        if ((_b = (_a = value === null || value === void 0 ? void 0 : value.DangerousGoods) === null || _a === void 0 ? void 0 : _a.vehicleType) === null || _b === void 0 ? void 0 : _b.label) {
            value.DangerousGoods.vehicleType =
                value.DangerousGoods.vehicleType.label;
        }
        this.DialogRef.close(value);
    }
    declineCollection() {
        // open the modal
        let config = new MatDialogConfig();
        config.disableClose = true;
        config.data = "collection";
        let DialogRef = this.Dialog.open(DeclineModal, config);
        DialogRef.afterClosed().subscribe((Decline) => {
            !!Decline && this.DialogRef.close({ decline: Decline });
        });
    }
    carrierCertificate() {
        let config = new MatDialogConfig();
        config.disableClose = true;
        config.data = this.FormGroup.get("Carrier");
        this.Dialog.open(CarrierCertificate, config);
    }
    consignorCertificate() {
        let config = new MatDialogConfig();
        config.disableClose = true;
        config.data = this.FormGroup.get("Consignor");
        this.Dialog.open(ConsignorCertificate, config);
    }
    dangerousGoodsNote() {
        let config = new MatDialogConfig();
        config.disableClose = true;
        config.data = this.FormGroup.get("DangerousGoods");
        this.Dialog.open(DangerousGoodsDialog, config);
    }
};
ViewCollection = __decorate([
    Component({
        selector: "view-collection",
        template: require('./view.html'),
        styles: [require('./view.scss')],
    }),
    __param(4, Inject(MAT_DIALOG_DATA)),
    __metadata("design:paramtypes", [MatDialog,
        MatDialogRef,
        FormBuilder,
        Auth, Object])
], ViewCollection);
export { ViewCollection };
