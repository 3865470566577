import { __decorate, __metadata, __param } from "tslib";
import { Component, Inject } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
let ConsignorCertificate = class ConsignorCertificate {
    constructor(DialogRef, FormGroup) {
        this.DialogRef = DialogRef;
        this.FormGroup = FormGroup;
    }
    onSubmit() {
        this.DialogRef.close();
    }
};
ConsignorCertificate = __decorate([
    Component({
        selector: "consignor-certificate",
        template: require('./consignor.html'),
        styles: [require('./certificate.scss')],
    }),
    __param(1, Inject(MAT_DIALOG_DATA)),
    __metadata("design:paramtypes", [MatDialogRef,
        FormGroup])
], ConsignorCertificate);
export { ConsignorCertificate };
