import { __decorate, __metadata, __param } from "tslib";
import { Component, Inject } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
let CarrierCertificate = class CarrierCertificate {
    constructor(DialogRef, FormGroup) {
        this.DialogRef = DialogRef;
        this.FormGroup = FormGroup;
    }
    ngOnInit() {
        this.FormGroup.get("wasteImage").setValidators(Validators.required);
    }
    fileChange(event) {
        let fileList = event.target.files;
        if (fileList.length > 0) {
            let reader = new FileReader();
            reader.onload = ((file) => (event) => {
                this.addFile(event.target.result);
            })(event.target.files[0]);
            reader.readAsDataURL(event.target.files[0]);
        }
    }
    addFile(src) {
        this.FormGroup.get("wasteImage").patchValue(src);
    }
    removeWasteImage() {
        this.FormGroup.get("wasteImage").reset();
    }
    onSubmit() {
        this.DialogRef.close();
    }
};
CarrierCertificate = __decorate([
    Component({
        selector: "carrier-certificate",
        template: require('./carrier.html'),
        styles: [require('./certificate.scss')],
    }),
    __param(1, Inject(MAT_DIALOG_DATA)),
    __metadata("design:paramtypes", [MatDialogRef,
        FormGroup])
], CarrierCertificate);
export { CarrierCertificate };
