import { __awaiter, __decorate, __metadata } from "tslib";
import { Component, Input, ViewChild } from "@angular/core";
import { Loader } from "@googlemaps/js-api-loader";
import { AwSnackBar } from "../../../services/aw-snack-bar";
import image from "./gps_fixed.svg";
import isIOS from "../../../utils/isIOS";
import { GPS } from "../../../services/gps";
import { BehaviorSubject } from "rxjs";
let Map = class Map {
    constructor(snackBar, GPS) {
        this.snackBar = snackBar;
        this.GPS = GPS;
        this.loading = true;
        this.currentLocation = new BehaviorSubject({});
    }
    ngAfterViewInit() {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            this.destination = {
                lat: (_a = this.data) === null || _a === void 0 ? void 0 : _a.latitude,
                lng: (_b = this.data) === null || _b === void 0 ? void 0 : _b.longitude,
            };
            yield this.GPS.getCurrentLocation().subscribe((coordinates) => __awaiter(this, void 0, void 0, function* () {
                let value = yield coordinates;
                if (value.latitude && value.longitude) {
                    this.currentLocation.next({
                        lat: value.latitude,
                        lng: value.longitude,
                    });
                    this.loading = false;
                    this.loadMap();
                }
            }));
        });
    }
    loadMap() {
        return __awaiter(this, void 0, void 0, function* () {
            // AnyWaste Api Key - contact Damian for details
            const loader = new Loader({
                apiKey: "AIzaSyBxmL996Y4JyIGGqG00ty4oIHGGLgb4QYw",
                version: "weekly",
                libraries: ["places"],
            });
            if (this.currentLocation.value) {
                try {
                    const google = yield loader.load();
                    const directionsService = new google.maps.DirectionsService();
                    const directionsRenderer = new google.maps.DirectionsRenderer();
                    this.calculateAndDisplayRoute(directionsService, directionsRenderer);
                    const map = new google.maps.Map(this.mapContainer.nativeElement, {
                        center: this.currentLocation.value,
                        zoom: 12,
                    });
                    directionsRenderer.setMap(map);
                    var bounds = new google.maps.LatLngBounds();
                    var markers = [this.currentLocation.value, this.destination];
                    for (var i = 0; i < markers.length; i++) {
                        bounds.extend(markers[i]);
                    }
                    map.fitBounds(bounds);
                    new google.maps.Marker({
                        position: this.currentLocation.value,
                        map: map,
                        icon: image,
                    });
                }
                catch (error) {
                    this.snackBar.handleError(error);
                }
            }
        });
    }
    calculateAndDisplayRoute(directionsService, directionsRenderer) {
        directionsService
            .route({
            origin: this.currentLocation.value,
            destination: this.destination,
            travelMode: "DRIVING",
        })
            .then((response) => {
            directionsRenderer.setDirections(response);
        })
            .catch((e) => console.log("Directions request failed due to " + e));
    }
    getCurrentLocation() {
        return new Promise((resolve, reject) => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position) => {
                    resolve({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    });
                }, () => {
                    reject(new Error("Failed to retreive location."));
                });
            }
            else {
                // Browser doesn't support Geolocation
                reject(new Error("Browser does not support GeoLocation."));
            }
        });
    }
    navigate() {
        let isApple = isIOS();
        if (isApple) {
            window.open(`https://maps.apple.com/?api=1&daddr=${this.destination.lat},${this.destination.lng}&travelmode=driving`);
        }
        else {
            /* else use Google */
            window.open(`https://www.google.com/maps/dir/?api=1&destination=${this.destination.lat},${this.destination.lng}&travelmode=driving`);
        }
        // link to open native google map for apple app if needed
        // `maps://www.google.com/maps/dir/?api=1&destination=${this.destination.lat},${this.destination.lng}&travelmode=driving
    }
};
__decorate([
    ViewChild("map"),
    __metadata("design:type", Object)
], Map.prototype, "mapContainer", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], Map.prototype, "data", void 0);
Map = __decorate([
    Component({
        selector: "map",
        template: require('./map.html'),
        styles: [require('./map.scss')],
    }),
    __metadata("design:paramtypes", [AwSnackBar, GPS])
], Map);
export { Map };
