import { __decorate } from "tslib";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatDialogModule } from "@angular/material/dialog";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { MatIconModule } from "@angular/material/icon";
let AnyWasteMaterialModule = class AnyWasteMaterialModule {
};
AnyWasteMaterialModule = __decorate([
    NgModule({
        imports: [
            BrowserAnimationsModule,
            ReactiveFormsModule,
            MatDialogModule,
            MatButtonModule,
            MatSelectModule,
            MatInputModule,
            MatTabsModule,
            MatSidenavModule,
            MatCardModule,
            MatToolbarModule,
            MatAutocompleteModule,
            MatSnackBarModule,
            MatTableModule,
            MatPaginatorModule,
            MatSortModule,
            MatIconModule,
        ],
        exports: [
            BrowserAnimationsModule,
            ReactiveFormsModule,
            MatDialogModule,
            MatButtonModule,
            MatSelectModule,
            MatInputModule,
            MatTabsModule,
            MatSidenavModule,
            MatCardModule,
            MatToolbarModule,
            MatAutocompleteModule,
            MatSnackBarModule,
            MatTableModule,
            MatPaginatorModule,
            MatSortModule,
            MatIconModule,
        ],
    })
], AnyWasteMaterialModule);
export { AnyWasteMaterialModule };
