import { __decorate, __metadata } from "tslib";
import { Component } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AwSnackBar } from "../../../services/aw-snack-bar";
import { Auth } from "../../../services/auth";
import { Api } from "../../../services/api";
import { Rounds } from "../../../services/rounds";
let DriverLogin = class DriverLogin {
    constructor(FormBuilder, Api, Auth, Rounds, Router, snackBar) {
        this.FormBuilder = FormBuilder;
        this.Api = Api;
        this.Auth = Auth;
        this.Rounds = Rounds;
        this.Router = Router;
        this.snackBar = snackBar;
        this.loading = false;
    }
    ngOnInit() {
        this.FormGroup = this.FormBuilder.group({
            driverName: [null, [Validators.required]],
            vehicleRegistration: [null, [Validators.required]],
            pinCode: [null, [Validators.required]],
        });
        this.Rounds.countUnsavedSignatures();
    }
    onSubmit() {
        let login = this.FormGroup.getRawValue();
        // start loading
        this.loading = true;
        this.Api.login(login)
            .then((VehicleAuths) => {
            this.Auth.setAuthFromLogin(login, VehicleAuths);
            this.Router.navigate(["/dashboard"]);
            this.loading = false;
        })
            .catch((error) => this.handleError(error));
    }
    handleError(error) {
        this.loading = false;
        this.snackBar.handleError(error);
    }
};
DriverLogin = __decorate([
    Component({
        selector: "driver-login",
        template: require('./component.html'),
        styles: [require('./component.scss')],
    }),
    __metadata("design:paramtypes", [FormBuilder,
        Api,
        Auth,
        Rounds,
        Router,
        AwSnackBar])
], DriverLogin);
export { DriverLogin };
