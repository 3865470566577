import { __decorate, __metadata, __param } from "tslib";
import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, Validators } from "@angular/forms";
let DeclineModal = class DeclineModal {
    constructor(DialogRef, FormBuilder, data) {
        this.DialogRef = DialogRef;
        this.FormBuilder = FormBuilder;
        this.data = data;
    }
    ngOnInit() {
        this.thingToDecline = this.data;
        this.FormGroup = this.FormBuilder.group({
            reason: [null, [Validators.required]],
        });
    }
    onSubmit() {
        let decline = this.FormGroup.getRawValue();
        decline.date = new Date();
        this.DialogRef.close(decline);
    }
};
DeclineModal = __decorate([
    Component({
        selector: "decline-modal",
        template: require('./component.html'),
        styles: [require('./component.scss')],
    }),
    __param(2, Inject(MAT_DIALOG_DATA)),
    __metadata("design:paramtypes", [MatDialogRef,
        FormBuilder, Object])
], DeclineModal);
export { DeclineModal };
