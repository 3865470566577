export default [
    {
        label: "Artic - Bulker Tipper",
        containerTypes: [{ label: "Loose" }],
    },
    {
        label: "Artic - Curtain Sider",
        containerTypes: [{ label: "Pallet" }, { label: "Loose" }],
    },
    {
        label: "Artic - Ejector Trailer",
        containerTypes: [{ label: "Loose" }],
    },
    {
        label: "Artic - Freight",
        containerTypes: [{ label: "Shipping Container" }],
    },
    {
        label: "Artic - Walking Floor",
        containerTypes: [{ label: "Pallet" }, { label: "Loose" }],
    },
    {
        label: "BoxVan 7.5t",
        containerTypes: [{ label: "Pallet" }, { label: "Loose" }],
    },
    {
        label: "BoxVan 12t",
        containerTypes: [{ label: "Pallet" }, { label: "Loose" }],
    },
    {
        label: "BoxVan 18t",
        containerTypes: [{ label: "Pallet" }, { label: "Loose" }],
    },
    {
        label: "Moffet",
        containerTypes: [{ label: "Pallet" }, { label: "Loose" }],
    },
    {
        label: "Rolonof",
        containerTypes: [
            { label: "Roll On" },
            { label: "Roll Off" },
            { label: "Enclosed" },
        ],
        containerSizes: [{ label: "30 Yard" }, { label: "40 Yard" }],
    },
    {
        label: "Skip - MiniSkip",
        containerTypes: [{ label: "Skip" }, { label: "Enclosed" }],
        containerSizes: [
            { label: "2 Yard" },
            { label: "4 Yard" },
            { label: "6 Yard" },
            { label: "8 Yard" },
            { label: "10 Yard" },
            { label: "12 Yard" },
            { label: "14 Yard" },
            { label: "16 Yard" },
            { label: "20 Yard" },
        ],
    },
    {
        label: "Skip",
        containerTypes: [{ label: "Skip" }, { label: "Enclosed" }],
        containerSizes: [
            { label: "2 Yard" },
            { label: "4 Yard" },
            { label: "6 Yard" },
            { label: "8 Yard" },
            { label: "10 Yard" },
            { label: "12 Yard" },
            { label: "14 Yard" },
            { label: "16 Yard" },
            { label: "20 Yard" },
        ],
    },
    {
        label: "Transit",
        containerTypes: [{ label: "Loose" }],
    },
    {
        label: "Van",
        containerTypes: [
            { label: "Loose" },
            { label: "Barrel" },
            { label: "Pallet Cage" },
            { label: "Pallet Box" },
            { label: "Flo Tube Box" },
        ],
    },
];
