import { __decorate, __metadata } from "tslib";
import { Component, Input } from '@angular/core';
let ConsignmentWasteTable = class ConsignmentWasteTable {
};
__decorate([
    Input(),
    __metadata("design:type", Object)
], ConsignmentWasteTable.prototype, "Consignment", void 0);
ConsignmentWasteTable = __decorate([
    Component({
        selector: 'consignment-waste-table',
        template: require('./component.html'),
        styles: [require('./component.scss')]
    })
], ConsignmentWasteTable);
export { ConsignmentWasteTable };
